import { Injectable , Component} from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {cfg_site} from '../../config';

import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';

import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

export const urllink = cfg_site.API_URL; // this is your wordpress website url to change




/**
 * API pour app
 
 * http://localhost:868/wp-json/comptreso/v1/dep-rec
 
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class DataService {


  api: any = '';
  isloggedGlobal: any = false;
  displayName: any = "";
  timeAction: any  ;
  timeStart: any  ;
  // pos: any= [];
  
  constructor(
           private http: HttpClient,
           public auth: AuthService,
           private router: Router,

           
           ) {
   
  }
  




  async exportToExcel(data, filename) {
    {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, filename);
        XLSX.writeFile(wb, filename + '.xlsx');
    }
  }

  async exportToExcelReleve(comptes, filename) {
    {

     let  compte =  comptes[0];
     let  AnMoisTabs = compte['AnMoisTabs'] ;



      
        let releveTab = [] ;
        for (var i=0; i<AnMoisTabs.length; i++){
          let AnMoiligne = AnMoisTabs[i]['lignes'];

          console.log("AnMoiligne", AnMoiligne);
          for (var j=0; j<AnMoiligne.length; j++){
            let releve =    AnMoiligne[j];
            releveTab.push(releve);
          };

        };

        console.log("releveTab", releveTab);


        let recetteTab = [] ;

        for (var i=0; i<releveTab.length; i++){
          let recettes_depenses = releveTab[i]['recettes_depenses']  ;
          let rele_date = releveTab[i]['rele_date']  ;
          let rele_date_fr = releveTab[i]['rele_date_fr']  ;
          let rele_credit = releveTab[i]['rele_credit']  ;
          let rele_debit = releveTab[i]['rele_debit']  ;
          let rele_id = releveTab[i]['rele_id']  ;
          let typeregl_libel = releveTab[i]['typeregl_libel']  ;

          if (recettes_depenses != undefined){
             for (var j=0; j<recettes_depenses.length; j++){

              //let recette =    recettes[j];
              let r = recettes_depenses[j] ;
              let rece_type_nature = r['rece_type_nature'] ;

              if (rece_type_nature == 'R'){
                let recette ={rele_date:rele_date,rele_date_fr:rele_date_fr,
                  rece_montant : r['rece_montant'],rece_type_libel :r['rece_type_libel'] , 
                  rece_type_nature : r['rece_type_nature'],
                  memb_nom :r['memb_nom'] , memb_prenom :r['memb_prenom'],
                  typeregl_libel : typeregl_libel ,
                  rele_credit:rele_credit, rele_debit:rele_debit ,rele_id:rele_id
                };
                
                recetteTab.push(recette);
              };
            };
          };  
        };



        let depenseTab = [] ;

        for (var i=0; i<releveTab.length; i++){
          let recettes_depenses = releveTab[i]['recettes_depenses']  ;
          let rele_date = releveTab[i]['rele_date']  ;
          let rele_date_fr = releveTab[i]['rele_date_fr']  ;
          let rele_credit = releveTab[i]['rele_credit']  ;
          let rele_debit = releveTab[i]['rele_debit']  ;
          let rele_id = releveTab[i]['rele_id']  ;
          let typeregl_libel = releveTab[i]['typeregl_libel']  ;

          if (recettes_depenses != undefined){
             for (var j=0; j<recettes_depenses.length; j++){


              //let recette =    recettes[j];
              let r = recettes_depenses[j] ;
              let rece_type_nature = r['rece_type_nature'] ;
              if (rece_type_nature == 'D'){

                  let recette ={rele_date:rele_date,rele_date_fr:rele_date_fr,
                    rece_montant : r['rece_montant'],rece_type_libel :r['rece_type_libel'] , 
                    rece_type_nature : r['rece_type_nature'],
                    memb_nom :r['memb_nom'] , memb_prenom :r['memb_prenom'],
                    typeregl_libel : typeregl_libel ,
                    rele_credit:rele_credit, rele_debit:rele_debit ,rele_id:rele_id
                  };
                  
                  depenseTab.push(recette);
                };
            };
          };  
        };

    

        console.log("recetteTab", recetteTab);



        const AnMois_ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(AnMoisTabs);
        const releveTab_ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(releveTab);
        const recetteTab_ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(recetteTab);
        const depenseTab_ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(depenseTab);


        

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        
        XLSX.utils.book_append_sheet(wb, releveTab_ws, "Relevés");
        XLSX.utils.book_append_sheet(wb, recetteTab_ws, "Recettes");
        XLSX.utils.book_append_sheet(wb, depenseTab_ws, "Dépenses");

        XLSX.utils.book_append_sheet(wb, AnMois_ws, "Banque cumul mensuel");

        XLSX.writeFile(wb, filename + '.xlsx');
    }
  }

   
  CheckGoLogin(){ 
    if(!this.isloggedGlobal ){
      this.router.navigate(['/login']);
    }
  }

  
  getHeader(){ 
     this.timeAction =  Date.now() ; 
     // this.timeStart défini ds login.page.ts
    // console.log("this.timeAction " , this.timeAction )
    // console.log("this.timeStart " , this.timeStart )

    let intervale = (this.timeAction -this.timeStart )/1000 ;

    console.log("intervale " , intervale ) 
   let tempo = 15*60; //seconde 15 minutes
    // let tempo = 3; // 3secondes

    if(intervale < tempo ){ 
      //console.log("prolonge "  )
      this.timeStart = Date.now() ; 
    }else{ 
      this.isloggedGlobal = false ;
      this.displayName= "" ;
      localStorage.clear();
      this.router.navigate(['/login']);
      console.log("intervale depassé" )
    }


    let Token=  this.auth.getToken();
    if(!this.isloggedGlobal ){
      Token= "";
    }
    return {headers: new HttpHeaders({'Authorization':Token}) };
  };

  qst(options ){ 

    let params = new URLSearchParams();
    for(let key in options){
        params.set(key, options[key]) 
    }
    return params.toString();
  };



  getBanqueComptes() { 
    console.log("getBanqueComptes" )
    let api =  urllink + 'banque/comptes'; // ?an=' +an
    //console.log( "api" , api);
    return this.http.get(api,this.getHeader());
  }

 
  getimportBancaire(formData,compte_selected ) { 
    
    let api =  urllink + '/banque/import?compteid=' +  compte_selected ;
    //return this.http.get(api,this.getHeader());
    return this.http.post(api, formData , this.getHeader()  );

  }

  
 
  getimportDocument(formData,data) { 
    // console.log("data",data);
    // console.log("data qst",this.qst(data));
    let api =  urllink + '/document/import?' + this.qst(data);
    return this.http.post(api, formData , this.getHeader()  );
  }




  getRelevBancaire(data) { 
     // console.log("data",data);
    // console.log("data qst",this.qst(data));
    //let data = {"an":an, "mois_start":start,"mois_end": end, "listidcompte" :listidcompte } ;
    let api =  urllink + 'relevebancaire?' + this.qst(data)
    return this.http.get(api,this.getHeader());

  }



  getRecetteData() { 
    console.log("getRecetteData" )
    let api =  urllink + 'recettedata';
    console.log( "api" , api);
    return this.http.get(api,this.getHeader());
  }


  // createRecetteData(postData) { 
  //   console.log("createRecetteData",postData)
  //   let api =  urllink + 'recettecreate';
  //   console.log( "api" , api);
  //  // return this.http.get<any>(api, this.getHeader() );
  //  return this.http.post(api, postData , this.getHeader()  );


  // }

  deleleRecette(postData) { 
    //console.log("deleleRecette",postData)
   
    let api =  urllink + 'recettedelele?recette_depense_id='+postData.recette_depense_id +'&rele_id=' +postData.rele_id   ;
    console.log( "api" , api);
    return this.http.get<any>(api, this.getHeader() );

  }





  
  previsionValid(hrlev_id,valid) { 
    console.log("hrlev_id",hrlev_id);
    let api =  urllink + 'prevision/valid?valid='+valid +'&hrlev_id='+ hrlev_id   ;
    console.log( "api" , api);
    return this.http.get<any>(api, this.getHeader() );

  }

  previsionCreate(postData) { 
     let api =  urllink + 'prevision/create'  ;
      //console.log( "api" , api);
      return this.http.post(api, postData , this.getHeader()  );
  }


  
  
  generiquePost(chemin, postData) { 
    let  startTime = Date.now() ;
     let api =  urllink +  chemin  +"?d="+startTime;
    console.log( "api" , api);
    return this.http.post(api, postData , this.getHeader()  );

  }



}