import { NgModule , LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { HttpClientModule, HttpClient } from '@angular/common/http';

import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";

import { CommonModule } from '@angular/common';

import { IonicStorageModule } from '@ionic/storage-angular';


import {  NgChartsModule    , NgChartsConfiguration} from 'ng2-charts';
import { SwiperModule } from 'swiper/angular';

 
//import { BaseChartDirective } from 'ng2-charts';
//https://stackblitz.com/github/santam85/ng2-charts-line-template?file=README.md&preset=node

import { DatestartComponent } from './component/datestart/datestart.component';
 
import { ClipboardModule } from 'ngx-clipboard';

import { ScrollingModule } from '@angular/cdk/scrolling';

 

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
     IonicModule.forRoot(), 
     AppRoutingModule, 
     HttpClientModule,
     CommonModule, 
     IonicStorageModule.forRoot({ name: 'comptetreso_application' }),
   
     NgChartsModule ,
    SwiperModule,
    ClipboardModule,
    ScrollingModule
    
  
    ],
 
  providers: [
   
   
   // { provide: NgChartsConfiguration, useValue: { generateColors: false }},

    { provide: RouteReuseStrategy,  useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "fr-ca" },
   
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

registerLocaleData(localeFr, "fr");
