
export let cfg_site = {
  
  //  API_URL: 'http://192.168.0.49:868//wp-json/comptreso/v1/',  
   //          API_SITE: 'http://192.168.0.49:868/',  
        API_URL: 'https://comptreso.back.digistella.com/wp-json/comptreso/v1/',  
       API_SITE: 'https://comptreso.back.digistella.com',
   
};

