import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
 
import {cfg_site} from '../../config';
export const urllink = cfg_site.API_SITE;
//export const urllink = cfg_site.API_URL; // comptreso/v1/
 


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated = new BehaviorSubject<boolean>(false);
  user = new Subject<any>();
  email = new Subject<any>();

  constructor(
    private http: HttpClient,
    private   router: Router,) { }


  private saveToken(token){
    localStorage.setItem('token', "Bearer "+ token);
  }

  getToken(){
   return localStorage.getItem('token');
  }

   saveName(displayName){
    // localStorage.setItem('displayName',displayName);
     this.user.next({displayName: displayName});
  }
  
  getName(): Observable<any>{
  //  return localStorage.getItem('displayName');
   return this.user.asObservable();
  }

  saveEmail(user_email){
    // localStorage.setItem('user_email',user_email);
    this.email.next({user_email: user_email});
  }

  getEmail(){
    // return localStorage.getItem('user_email');
    return this.email.asObservable();
  }

  postLogin(username, password) {

    let data = {
      username: username,
      password: password
    }

    // let headers = new HttpHeaders();
    // headers.set('Content-Type', 'application/json');

    const optionRequete = {
      headers: new HttpHeaders({ 
        'Access-Control-Allow-Origin':'*',
        //'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
         
        
      })
    };

    //let headers = new HttpHeaders().set('access-control-allow-origin',"http://localhost:868/"); 
 // https://forum.ionicframework.com/t/how-to-setup-the-proxy/140122
 // ionic.config.json
//  "proxies": [
//   {
//     "path": "/wp-json/jwt-auth/*",
//     "proxyUrl": "http://localhost:868/*"
//   }
// ]

  let url =urllink + '/wp-json/jwt-auth/v1/token' 
  ///`${urllink}/wp-json/jwt-auth/v1/token`

  

   return new Promise ((resolve) => {
    this.http.post(url, data ,optionRequete ).subscribe(data => {
      resolve(data)
      this.saveToken(data['token']);

      this.router.navigate(['/synthese']);
      //setTimeout(() => {this.router.navigate(['/synthese'])}, 200);
      // this.saveName(data['user_display_name']);
      // this.saveEmail(data['user_email']);
    }, (errdata) => {
     
      localStorage.setItem('token', '');
      localStorage.setItem('displayName', '');
      localStorage.setItem('user_email', '');
      resolve(errdata)
    }
    )
   })
   
  };

  postRegister(username,email,password) {

    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    let data = {
      username: username,
      email: email,
      password: password
    }

    return new Promise ((resolve) => {
      this.http.post(`${urllink}/wp-json/wp/v2/users/register`, data, {headers}).subscribe(data => {
        resolve(data);
      }, (errdata) => {
        resolve(errdata);
      }
      )
    })
    
  }


  forgetPassword(data){
    // let headers = new HttpHeaders();
    // headers.set('Content-Type', 'application/json');

    return new Promise ((resolve) => {
      this.http.post(`${urllink}/wp-json/wp/v2/users/lostpassword`, data).subscribe(data => {
        resolve(data)
      }, (errdata) => {
        resolve(errdata)
      }
      )
    })
    
  }

}
