import { Component } from '@angular/core';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

 
//import { environment } from './environments/environment';
import { DataService} from './services/data.service';
import { Router } from '@angular/router';



import { defineCustomElements } from '@ionic/pwa-elements/loader';
 

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
 
   public islogged  ;
   private router: Router ;
   public appPages = [  ];
   public displayName ="";
 
  constructor( 
    private NsDataService: DataService,
    ) {

    defineCustomElements(window);
     // if (environment.production) {
        //   enableProdMode();
        // }

        // platformBrowserDynamic()
        // .bootstrapModule(AppModule)
        // .catch(err => console.log(err));

        //   // Call the element loader after the platform has been bootstrapped
        //   defineCustomElements(window);

        this.islogged = this.NsDataService.isloggedGlobal ;

    }

    ngOnInit() {

      this.NsDataService.timeStart=  Date.now() ;  
      this.displayName = localStorage.getItem('displayName' )
    // this.displayName =  this.NsDataService.displayName
     // this.NsDataService.displayName =this.displayName;
       
       if ( this.displayName  ){ 
         this.NsDataService.isloggedGlobal = true;
         this.NsDataService.displayName =this.displayName;
      //   // this.router.navigate(['/banque/releve']);
         
        }
    
      
          this.appPages = [
            { title: 'Plan', url: '/plan/liste/', icon: 'mail' },
            { title: 'Import', url: '/banque/import/', icon: 'mail' },
          
           
          
            { title: 'Document ', url: '/document', icon: 'mail' },
            { title: 'Cotisation', url: '/cotisation', icon: 'mail' },
            { title: 'Banque ', url: '/banque', icon: 'mail' },
          
            { title: 'Résultat', url: '/resultat', icon: 'mail' },
            
          ];

      
     

    }

    onDeconnect() {
     
     
      this.NsDataService.isloggedGlobal = false ;
      this.NsDataService.displayName= "" ;
      localStorage.clear();

      //this.router.navigate(['/login']);
      

    }
}
