import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  
  {
    path: 'banque',
    loadChildren: () => import('./banque/releve/releve.module').then( m => m.RelevePageModule)
  },
  {
    path: 'banque/import',
    loadChildren: () => import('./banque/import/import.module').then( m => m.ImportPageModule)
  },
  {
    path: 'recettedit',
    loadChildren: () => import('./banque/recettedit/recettedit.module').then( m => m.RecetteditPageModule)
  },
 
  {
    path: 'resultat',
    loadChildren: () => import('./resultat/depenserecette/depenserecette.module').then( m => m.DepenserecettePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  }
  ,
  {
    path: 'forget',
    loadChildren: () => import('./forget/forget.module').then( m => m.ForgetPageModule)
  },
   
 
  
  {
    path: 'document/doc-edit',
    loadChildren: () => import('./document/doc-edit/doc-edit.module').then( m => m.DocEditPageModule)
  },
  {
    path: 'document',
    loadChildren: () => import('./document/doc-liste/doc-liste.module').then( m => m.DocListePageModule)
  },
 
  {
    path: 'document/doc-read/:doc_id',
    loadChildren: () => import('./document/doc-read/doc-read.module').then( m => m.DocReadPageModule)
  },
  {
    path: 'plan/liste',
    loadChildren: () => import('./plan/plan-liste/plan-liste.module').then( m => m.PlanListePageModule)
  },
  {
    path: 'plan/edit/:rece_type_id',
    loadChildren: () => import('./plan/plan-edit/plan-edit.module').then( m => m.PlanEditPageModule)
  },
  {
    path: 'reglement',
    loadChildren: () => import('./reglement/reglement.module').then( m => m.ReglementPageModule)
  },


  {
    path: 'cotisation',
    loadChildren: () => import('./membre/cotisation/cotisation.module').then( m => m.CotisationPageModule)
  },
 
  {
    path: 'membre/edit',
    loadChildren: () => import('./membre/membre-edit/membre-edit.module').then( m => m.MembreEditPageModule)
  },
 
  {
    path: 'synthese',
    loadChildren: () => import('./synthese/synthese.module').then( m => m.SynthesePageModule)
  },
  {
    path: 'prevision-edit',
    loadChildren: () => import('./resultat/prevision-edit/prevision-edit.module').then( m => m.PrevisionEditPageModule)
  },
 

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
